import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ParallaxHeader from "../components/ParallaxHeader";
import Content, { HTMLContent } from "../components/Content";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import Markdown from "markdown-to-jsx";
import { Helmet } from "react-helmet";
// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  content,
  message,
  markets,
  contentComponent,
  team,
  intro,
  image,
  gsapdf,
}) => {
  const PageContent = contentComponent || Content;
  const headerImage = getImage(image) || image;
  return (
    <div className="flex flex-col  items-center justify-center ">
      <ParallaxHeader className="h-80" image={headerImage} factor={0.7}>
        <motion.h1
          initial={{ x: -10, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "ease",
            },
          }}
          className="z-10 text-white text-5xl mb-10"
        >
          {title}
        </motion.h1>
      </ParallaxHeader>
      <section className="bg-darkbrown/[0.6] w-full flex flex-col items-center h-full">
        <motion.div
          initial={{ y: -50 }}
          animate={{ y: -100, transition: { type: "spring" } }}
          className="container px-8 py-10 bg-white mx-5 rounded-sm shadow-lg "
        >
          <div className="flex flex-col sm:flex-row items-center justify-center">
            <div className="flex w-60 h-auto shrink-0 mx-10">
              <GatsbyImage
                image={getImage(intro.logo)}
                imgStyle={{ width: "100%", height: "auto" }}
              />
            </div>
            <div>
              <p className="text-navy text-lg pb-5 sm:mr-10 mt-6 sm:mt-0 ">
                <Markdown className="md-content">{intro.content}</Markdown>
              </p>
            </div>
          </div>
          {/* <h1 className="text-2xl text-darkbrown text-center">
            BCS is a woman owned small business
          </h1> */}
        </motion.div>
        {/* <section className="bg-brown text-white p-10 mt-[-50px] w-full">
          <h1 className="text-4xl text-center">
            BCS is a woman-owned small business
          </h1>
        </section> */}
        <section className="bg-navy flex flex-col items-center w-full mt-[-30px]">
          <div className="container p-4 sm:p-10">
            <h1 className="text-center text-white text-4xl mb-6">
              {team.title}
            </h1>
            <div className="flex flex-col-reverse items-center justify-center xl:grid xl:grid-cols-3 items-center">
              <div className="col-span-2 flex flex-col items-center md:items-start">
                <h1 className="text-white text-2xl mb-5">{team.header}</h1>
                <p className="text-white/[0.5] mb-5">{team.content}</p>
                <Link to="/about/team">
                  <button
                    className={`text-md bg-white text-navy p-3 rounded-sm hover:bg-navyHover w-80 flex flex-row justify-center items-center`}
                  >
                    Meet the Team
                  </button>
                </Link>
              </div>
              <div className="shadow-md mb-6 xl:mb-0 shadow-white/[0.4] sm:mx-5">
                <GatsbyImage image={getImage(team.image)} />
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="bg-beige-300/[0.3] flex flex-col items-center w-full ">
        <div className="container py-10 flex flex-col items-center justify-center">
          <h1 className="text-darkbrown text-4xl text-center mb-4">
            {message.header}
          </h1>
          <div className="bg-brown/[0.8] max-w-[1200px] m-5">
            <div className="shadow-lg max-w-[1200px] bg-white md:translate-y-3 md:translate-x-3 p-6 sm:p-10 md:p-20">
              <img
                className="m-5 hidden md:block mt-[-30px] ml-20"
                src="/img/logo-color.jpg"
                style={{ width: "150px", height: "auto", float: "right" }}
              />
              <Markdown className="md-content text-black">
                {message.content}
              </Markdown>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-beige-300/ flex flex-col items-center w-full ">
        <div className="container p-6 md:p-10 ">
          <h1 className="text-brown text-4xl text-center mb-4">
            Markets Served
          </h1>
          <div className="md-content md-theme-color">
            <h1>Federal Market</h1>
            <p>
              BCS's current workload is diverse and includes both firm fixed
              price and IDIQ (task-order type) contracts under the{" "}
              <a href={gsapdf.publicURL} target="_blank">
                GSA Multiple Award Schedule: GS-00F-163CA
              </a>
            </p>
          </div>
          <Markdown className="md-content md-theme-color">{markets}</Markdown>
        </div>
      </section>
    </div>
  );
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <Helmet>
        <title>About BCS - Brown Construction Services</title>
      </Helmet>
      <AboutPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        intro={frontmatter.intro}
        team={frontmatter.team}
        message={frontmatter.message}
        markets={frontmatter.markets}
        gsapdf={frontmatter.gsapdf}
      />
    </>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        intro {
          logo {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 300)
            }
          }
          content
        }
        team {
          title
          header
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 500)
            }
          }
        }
        message {
          header
          content
        }
        markets
        gsapdf {
          publicURL
        }
      }
    }
  }
`;
